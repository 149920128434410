#disqus_thread {
  margin-top: 100px;
}

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, 'Hiragino Sans GB', Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;

  @media (min-width: 38em) {
    font-size: 20px;
  }
}

body {
  color: #515151;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

a {
  color: #268bd2;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.1;
  color: #333;
  letter-spacing: -.025rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
}

h4, h5, h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
}

ul {
  list-style: inside circle;
  padding-left: 0;

  li {
    margin-bottom: .25rem;
  }

  ul,
  ol {
    margin-top: .25rem;
    margin-bottom: .5rem;
  }
}

ol {
  list-style: inside decimal;
  padding-left: 0;

  li {
    margin-bottom: .25rem;
  }

  ul,
  ol {
    margin-top: .25rem;
    margin-bottom: .5rem;
  }
}

p + h1,
p + h2,
p + h3,
ul + h1,
ul + h2,
ul + h3,
ol + h1,
ol + h2,
ol + h3 {
  margin-top: 1.5rem;
}

hr {
  border: 0;
  border-top: .5px solid #eee;
  border-bottom: .5px solid #eee;
  margin: 1.5rem auto;
}

strong {
  color: #333;
  font-weight: 600;
}

abbr {
  font-size: 85%;
  font-weight: bold;
  color: #555;
  text-transform: uppercase;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #e5e5e5;
}

blockquote {
  padding: 0 0 0 1.5rem;
  margin: 0 2rem 1rem 0;
  color: #777;
  border-left: .5rem solid #e5e5e5;

  p:last-child {
    margin-bottom: 0;
  }
}

code,
pre {
  font-family: Menlo, Monaco, "Courier New", monospace;
}

code {
  padding: .25em .5em;
  font-size: 85%;
  color: #bf616a;
  background-color: #f9f9f9;
  border-radius: 3px;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  // word-wrap: break-word;
  background-color: #f9f9f9;
}

pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent;
}

.highlight {
  margin-bottom: 1rem;
  border-radius: 4px;
}

.highlight pre {
  margin-bottom: 0;
}

.gist .gist-file {
  font-family: Menlo, Monaco, "Courier New", monospace !important;
}

.gist .markdown-body {
  padding: 15px;
}

.gist pre {
  padding: 0;
  background-color: transparent;
}

.gist .gist-file .gist-data {
  font-size: .8rem !important;
  line-height: 1.4;
}

.gist code {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

.lead {
  font-size: 1.25rem;
}

.container {
  max-width: 38rem;
  padding: 2rem 1.5rem;
  margin-left:  auto;
  margin-right: auto;

  @media (min-width: 38rem){
    padding: 5rem 1rem;
  }
}

.masthead {

  &-title {
    font-weight: 300;

    a {
      color: #333;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    span {
      color: #ddd;
    }

  }

  &-title--small {
    margin-bottom: .75rem;
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase;

    a {
      color: #333;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .masthead-nav {
    margin-bottom: 2rem;

    a {
      color: #aaa;
      font-weight: 300;
      -webkit-transition: all .2s ease;
         -moz-transition: all .2s ease;
              transition: all .2s ease;

      &:hover,
      &:focus {
        color: #333;
        text-decoration: none;
      }

      + a {
        margin-left: 1.5rem;
      }
    }
  }

}

.page {
  &-title {
    margin-bottom: 2rem;
  }
}

.post {
  &-title {
    margin-bottom: .25rem;
  }
  &-date {
    margin-bottom: 2rem;
    font-size: .75rem;
    text-transform: uppercase;
  }
}

.list {
  &-post-title {
    margin-bottom: .25rem;

    a {
      color: #333;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  &-post-date {
    margin-bottom: 1rem;
    font-size: .75rem;
    text-transform: uppercase;
  }
}

.projects-list {
  a {
    color: #333;
  }
}

.highlight .hll { background-color: #ffffcc }
.highlight .c { color: #999988; font-style: italic } /* Comment */
.highlight .err { color: #a61717; background-color: #e3d2d2 } /* Error */
.highlight .k { color: #000000; font-weight: bold } /* Keyword */
.highlight .o { color: #000000; font-weight: bold } /* Operator */
.highlight .cm { color: #999988; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #999999; font-weight: bold; font-style: italic } /* Comment.Preproc */
.highlight .c1 { color: #999988; font-style: italic } /* Comment.Single */
.highlight .cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
.highlight .gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
.highlight .ge { color: #000000; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #aa0000 } /* Generic.Error */
.highlight .gh { color: #999999 } /* Generic.Heading */
.highlight .gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
.highlight .go { color: #888888 } /* Generic.Output */
.highlight .gp { color: #555555 } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #aaaaaa } /* Generic.Subheading */
.highlight .gt { color: #aa0000 } /* Generic.Traceback */
.highlight .kc { color: #000000; font-weight: bold } /* Keyword.Constant */
.highlight .kd { color: #000000; font-weight: bold } /* Keyword.Declaration */
.highlight .kn { color: #000000; font-weight: bold } /* Keyword.Namespace */
.highlight .kp { color: #000000; font-weight: bold } /* Keyword.Pseudo */
.highlight .kr { color: #000000; font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #445588; font-weight: bold } /* Keyword.Type */
.highlight .m { color: #009999 } /* Literal.Number */
.highlight .s { color: #d01040 } /* Literal.String */
.highlight .na { color: #008080 } /* Name.Attribute */
.highlight .nb { color: #0086B3 } /* Name.Builtin */
.highlight .nc { color: #445588; font-weight: bold } /* Name.Class */
.highlight .no { color: #008080 } /* Name.Constant */
.highlight .nd { color: #3c5d5d; font-weight: bold } /* Name.Decorator */
.highlight .ni { color: #800080 } /* Name.Entity */
.highlight .ne { color: #990000; font-weight: bold } /* Name.Exception */
.highlight .nf { color: #990000; font-weight: bold } /* Name.Function */
.highlight .nl { color: #990000; font-weight: bold } /* Name.Label */
.highlight .nn { color: #555555 } /* Name.Namespace */
.highlight .nt { color: #000080 } /* Name.Tag */
.highlight .nv { color: #008080 } /* Name.Variable */
.highlight .ow { color: #000000; font-weight: bold } /* Operator.Word */
.highlight .w { color: #bbbbbb } /* Text.Whitespace */
.highlight .mf { color: #009999 } /* Literal.Number.Float */
.highlight .mh { color: #009999 } /* Literal.Number.Hex */
.highlight .mi { color: #009999 } /* Literal.Number.Integer */
.highlight .mo { color: #009999 } /* Literal.Number.Oct */
.highlight .sb { color: #d01040 } /* Literal.String.Backtick */
.highlight .sc { color: #d01040 } /* Literal.String.Char */
.highlight .sd { color: #d01040 } /* Literal.String.Doc */
.highlight .s2 { color: #d01040 } /* Literal.String.Double */
.highlight .se { color: #d01040 } /* Literal.String.Escape */
.highlight .sh { color: #d01040 } /* Literal.String.Heredoc */
.highlight .si { color: #d01040 } /* Literal.String.Interpol */
.highlight .sx { color: #d01040 } /* Literal.String.Other */
.highlight .sr { color: #009926 } /* Literal.String.Regex */
.highlight .s1 { color: #d01040 } /* Literal.String.Single */
.highlight .ss { color: #990073 } /* Literal.String.Symbol */
.highlight .bp { color: #999999 } /* Name.Builtin.Pseudo */
.highlight .vc { color: #008080 } /* Name.Variable.Class */
.highlight .vg { color: #008080 } /* Name.Variable.Global */
.highlight .vi { color: #008080 } /* Name.Variable.Instance */
.highlight .il { color: #009999 } /* Literal.Number.Integer.Long */

$breakpoint_iphone_se: 319.98px;
$breakpoint_iphone_7_to_x: 374.98px;
$breakpoint_iphone_xs_max: 413.98px;
$breakpoint_xs: 575.98px;
$breakpoint_sm: 767.98px;
$breakpoint_md: 991.98px;
$breakpoint_lg: 1199.98px;
$breakpoint_xl: 1899.98px;

.nav-pills .nav-link {
  border-radius: 0;
}

.alert p:last-child {
  margin-bottom: 0;
}

#toko-book-club {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, 'Hiragino Sans GB', Arial, sans-serif;

  .hidden {
    display: none;
  }

  header {
    background: #000 url('https://farm5.staticflickr.com/4888/31951762578_0267f88d3d_o.jpg') no-repeat;
    background-size: cover;
    padding: 100px 0 70px;
    position: relative;
    color: #fff;

    h1 {
      color: #fff;
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .container {
      padding-top: 0;
      padding-bottom: 0;
    }

    .masthead-title {
      font-weight: bold;
      font-size: 4rem;

      @media screen and (max-width: 400px) {
        font-size: 2.5rem;
      }

      a {
        text-decoration: none;
      }
    }

    .slogan {
      font-size: 1.4em;
      margin-bottom: 3.5rem;
    }

    .meta {
      color: #ddd;
      font-size: 1em;
      font-size: 0.9rem;

      a {
        color: #000;
        background: #fff;
        padding: 4px 8px;
        border: 1px solid #fff;

        &:hover {
          text-decoration: none;
          background: transparent;
          color: #fff;
        }
      }
    }

    .photo {
      font-size: 0.6rem;
      color: #aaa;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .next-event {
      dl {
        margin: 0;
      }

      .title {
        background: #fff;
        color: #000;
        padding: 4px 8px;
        margin: 0 0 15px;
        display: inline-block;
        font-size: 0.9em;
      }

      dl {
        overflow: hidden;
        color: #fff;
        font-size: 0.9em;

        dt {
          float: left;
          font-weight: normal;

          &:after {
            content: "：";
          }
        }
      }
    }
  }

  #content {
    .container {
      padding: 2rem 1rem 0;
    }

    section {
      margin: 0 0 70px;

      &.last-child {
        margin: 0;
      }
    }

    #events .event {
      margin: 0 0 15px;

      &:last-child {
        margin: 0;
      }

      .date span {
        display: inline-block;
        border-bottom: 1px solid;
        margin-bottom: 10px;
      }

      .meta li {
        margin: 0;
      }
    }

    #copyright {
      color: #ccc;
      font-size: 0.7rem;
    }
  }
}

#service {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, 'Hiragino Sans GB', Arial, sans-serif;

  .hidden {
    display: none;
  }

  header {
    background: #000 url('https://live.staticflickr.com/65535/49519552583_be4cea8804_o.jpg') no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    background-position-y: -50px;
    padding: 200px 0 100px;

    @media screen and (max-width: $breakpoint_iphone_se) {
      background-position-x: -120px;
    }

    @media screen and (min-width: $breakpoint_iphone_se) and (max-width: $breakpoint_iphone_7_to_x) {
      padding: 1rem;
      background-position-x: -7rem;
      background-position-y: 0;
    }

    @media screen and (min-width: $breakpoint_iphone_7_to_x) and (max-width: $breakpoint_iphone_xs_max) {
      padding: 1rem;
      background-position-x: -2rem;
      background-position-y: 0;
    }

    @media screen and (min-width: $breakpoint_iphone_xs_max) and (max-width: $breakpoint_xs) {
      padding: 1rem;
      background-position-x: 0;
      background-position-y: 0;
    }

    @media screen and (min-width: $breakpoint_xs) and (max-width: $breakpoint_sm) {
      background-position-x: 0;
    }

    @media screen and (min-width: $breakpoint_sm) and (max-width: $breakpoint_md) {
      background-position-x: 0;
      background-position-y: 0;
      padding: 100px 10px 50px 100px;
    }

    .container {
      padding: 0 15rem 0 0;

      @media screen and (max-width: $breakpoint_iphone_se) {
        padding: 0 3rem 0 0;
      }

      @media screen and (min-width: $breakpoint_iphone_se) and (max-width: $breakpoint_iphone_7_to_x) {
        padding: 0 3rem 0 0;
      }

      @media screen and (min-width: $breakpoint_iphone_7_to_x) and (max-width: $breakpoint_iphone_xs_max) {
        padding: 0 3rem 0 0;
      }

      @media screen and (min-width: $breakpoint_iphone_xs_max) and (max-width: $breakpoint_xs) {
        padding: 0 6rem 0 0;
      }

      @media screen and (min-width: $breakpoint_xs) and (max-width: $breakpoint_sm) {
        padding: 0 6rem 0 0;
      }

      @media screen and (min-width: $breakpoint_sm) and (max-width: $breakpoint_md) {
        // e.g. iPad
        padding: 0 15rem 0 0;
      }
    }

    .back {
      position: absolute;
      top: 1rem;
      font-size: 0.7rem;
      color: #ccc;
      left: 1rem;

      @media screen and (max-width: $breakpoint_sm) {
        left: inherit;
        right: 1rem;
      }
    }

    .masthead-title {
      font-weight: bold;
      color: #fff;
      font-size: 2.2rem;

      @media screen and (max-width: 400px) {
        font-size: 2.5rem;
      }
    }

    .introduction {
      font-size: 1em;
      margin: 1rem 0 2rem;
      line-height: 1.7rem;
    }

    .cta {
      color: #fff;
      background: transparent;
      padding: 4px 8px;
      border: 1px solid #fff;
      text-decoration: none;

      &:hover {
        background: #fff;
        color: #000;
      }
    }
  }

  #content {
    .container {
      padding: 2rem 1rem 0;
    }

    section {
      margin: 0 0 70px;
    }

    h2 {
      margin: 0 auto 1.5rem;
    }

    #cta {
      color: #fff;
      text-decoration: none;
      padding: 10px 20px;
      color: #fff;
      background: #16ad6f;
      font-weight: bold;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }

    #copyright {
      color: #ccc;
      font-size: 0.7rem;
    }
  }

  img.thumbnail {
    border-radius: 0;
    display: inline-block;
    height: 140px;
    width: auto;

    @media screen and (max-width: $breakpoint_sm) {
      height: auto;
      width: 300px;
    }
  }

  ul.testimonials {
    li {
      margin: 0 0 30px;
      list-style: none;

      &:last-child {
        margin: 0;
      }
    }

    blockquote {
      margin: 0;
    }

    .profile {
      margin: 0 0 15px;
      overflow: hidden;
    }

    img.avatar {
      border-radius: 0;
      float: left;
      margin: 0 15px 0 0;
      width: 50px;
      height: auto;
    }

    .name {
      font-weight: bold;
    }

    .tagline {
      font-size: 0.7rem;
    }
  }
}

.post {
  img {
    border-radius: 0;
    margin: 0;

    &.bordered {
      border: 1px solid #ddd;
      padding: 5px;
      background-color: #ddd;
      display: inline-block;
      //box-shadow: 1px 3px 5px #ababab;
    }
  }

  .text-muted { color: #9d9d9d; }
  .list { padding-left: 2rem; }
  .list li:not(:last-child) { margin-bottom: 0.5rem; }
  .list small.text-muted { margin-left: 1.3rem; display: none; }

  h2 {
    margin: 2rem 0;
  }
}

.post .vpn {
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1em;
    font-weight: normal;
  }

  #menu-select {
    margin-bottom: 1em;
  }

  div.nav {
    margin-bottom: 40px;
  }

  .tab-pane ol {
    padding: 0;
    list-style: none;
    word-break: break-all;

    $padding: 30px;

    > li {
      margin-bottom: $padding;
      border-bottom: 1px solid #ccc;
      padding-bottom: $padding;
      padding-left: 1.5em;
      position: relative;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }

      @for $i from 1 to 10 {
        &:nth-child(#{$i}):before {
          position: absolute;
          content: "#{$i}. ";
          left: 0;
        }
      }

      .text {
        display: inline-block;
      }

      img {
        margin-top: $padding / 2;
        width: 200px;
        height: auto;
        border: 2px solid #eee;
      }
    }
  }

  .tab-pane#tab-appleid ol > li img {
    width: 240px;
  }
}


section {
  margin-bottom: 30px;

  &.last-child {
    margin-bottom: 0;
  }
}
